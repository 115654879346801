export default {
  common_btn_download_app: 'Download now',
  common_btn_prev: 'Previous',
  common_btn_next: 'Next',
  common_btn_read_more: 'Read more',

  about_us_section_title: 'Who is Splint Invest?',
  about_us_section_text_1:
    "“Isn't it unfair how alternative investments have so far been accessible mainly to wealthy individuals?” That’s what our founders have asked themselves and what inspired them to find a solution. Splint was born.",
  about_us_section_text_2:
    'In our team you’ll find experienced entrepreneurs, software experts, investment cracks and - especially important - lots of drive to create a sustainable system in which every person can benefit from all asset categories.',

  slogan_section_heading: 'Your new platform for alternative investments',
  slogan_section_quote:
    '"I don\'t have time to keep up with stock prices all the time. Whiskey, on the other hand, is tangible."',
  slogan_section_author: 'Fabian H., Zurich, Switzerland',

  how_to_step_1_headline: 'Download the app',
  how_to_step_1_text:
    'Are you using an iPhone or Android device? Great, that’s all you need to get started with Splint!',

  how_to_step_2_headline: 'Discover',
  how_to_step_2_text:
    'Whiskey, watches, wine… The world of alternative assets is quite diverse. And surely has something to match your taste as well.',

  how_to_step_3_headline: 'Choose your investment',
  how_to_step_3_text:
    'You’ll find everything you need to know about each asset in our app. You already know which one you’ll choose? Great! Then simply acquire a piece (Splint) of it for €50.',

  how_to_step_4_headline: 'Diversify your portfolio',
  how_to_step_4_text:
    'Congratulations! You just made your first step into the world of alternative investments. Check your portfolio and watch how your Splints develop over time.',

  blog_section_title: 'The most recent posts',

  what_is_splint_section_title: 'What’s a Splint?',
  what_is_splint_section_col_1_title: 'Definition.',
  what_is_splint_section_col_1_text:
    'First, our experts scan the markets for new investments. After identifying a good opportunity, we acquire it and split the asset virtually into fractions of €50 each. These fractions are called Splints.',
  what_is_splint_section_col_2_title: 'Benefit.',
  what_is_splint_section_col_2_text:
    'With every increase in the value of your investment, the value of your splints increases as well. When the asset is sold, you receive your share of the total price.',

  testimonials_section_title: 'Our users about us',
  testimonial_section_user_1_title: 'Siro V., Winterthur, Switzerland',
  testimonial_section_user_1_text:
    'The app is easy to use, but also provides a lot of information about each asset if you want to dig deeper.',
  testimonial_section_user_2_title: 'Pascal T., Biel, Switzerland',
  testimonial_section_user_2_text: 'Good app with very interesting investment opportunities - Can recommend.',
  testimonial_section_user_3_title: 'Sandra L., Basel, Switzerland',
  testimonial_section_user_3_text:
    'I always wanted to invest in wine, but I’m not much of an expert myself. App works great',

  opportunity_section_title: 'A great opportunity for 4 simple reasons',
  opportunity_section_item_1: 'Diversification',
  opportunity_section_item_2: 'Resistant against recession',
  opportunity_section_item_3: 'Beating inflation',
  opportunity_section_item_4: 'High performance',

  faq_section_title: 'Still have some questions before getting started?',
  faq_section_text:
    'Not convinced yet? No problem! If you need some additional information, feel free to head over to our FAQ. There, you’ll find more details about the app, our investment opportunities and Splints. ',
  faq_section_button: 'To the FAQ',

  footer_follow_text: 'Follow Splint',
  footer_contact_text: 'Contact',
  footer_text:
    'Splint Invest is your app for alternative investments. Invest in whiskey, wine, watches and more. Available for iOS and Android devices.',

  header_menu_title: 'Overview',

  header_menu_item_1_title: 'How it works',
  header_menu_item_1_text:
    'Not sure how to get started? You’re only a few steps aways from discovering the world of alternative investments.',

  header_menu_item_2_title: 'About us',
  header_menu_item_2_text:
    'Who’s actually behind Splint? Why did we start this journey? What keeps us going? Click here to find out.',

  header_menu_item_3_title: 'News',
  header_menu_item_3_text:
    'What’s new in the Splint App? Do we have new asset categories to talk about? Click here to get our latest news.',

  header_menu_item_4_title: 'Your FAQs',
  header_menu_item_4_text:
    'Still got some questions? No problem! Head over to our FAQs, you’ll definitely find what you’re looking for. And in case you don’t: simply shoot us a message!',

  footer_all_rights_reserved: '© 2021 Splint. All rights reserved.',
  footer_terms: 'Privacy policy',

  footer_lang_german: 'German',
  footer_lang_english: 'English',

  home_page_title: 'Home',
  not_found_page_title: 'Not found',
  not_found_text: 'Not found',

  meta_description: 'Your new platform for alternative investments.',
} as const;
