// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-3-grunde-um-in-alternative-anlagen-zu-investieren-de-tsx": () => import("./../../../src/pages/blog/3-grunde-um-in-alternative-anlagen-zu-investieren.de.tsx" /* webpackChunkName: "component---src-pages-blog-3-grunde-um-in-alternative-anlagen-zu-investieren-de-tsx" */),
  "component---src-pages-blog-3-reasons-to-get-started-en-tsx": () => import("./../../../src/pages/blog/3-reasons-to-get-started.en.tsx" /* webpackChunkName: "component---src-pages-blog-3-reasons-to-get-started-en-tsx" */),
  "component---src-pages-blog-how-splint-works-en-tsx": () => import("./../../../src/pages/blog/how-splint-works.en.tsx" /* webpackChunkName: "component---src-pages-blog-how-splint-works-en-tsx" */),
  "component---src-pages-blog-post-tsx": () => import("./../../../src/pages/blog/post.tsx" /* webpackChunkName: "component---src-pages-blog-post-tsx" */),
  "component---src-pages-blog-so-funktioniert-splint-de-tsx": () => import("./../../../src/pages/blog/so-funktioniert-splint.de.tsx" /* webpackChunkName: "component---src-pages-blog-so-funktioniert-splint-de-tsx" */),
  "component---src-pages-index-de-tsx": () => import("./../../../src/pages/index.de.tsx" /* webpackChunkName: "component---src-pages-index-de-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */)
}

