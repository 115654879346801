import i18n, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import EnTranslations from './translations/en';
import DeTranslations from './translations/de';

i18n.use(initReactI18next).init({
  fallbackLng: 'de',
  lng: 'de',
  resources: {
    en: {
      translation: EnTranslations,
    },
    de: {
      translation: DeTranslations,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});
export type MessageKey = keyof typeof EnTranslations;

export const translate = (key: MessageKey, options?: TOptions) => i18n.t(key, options);
