import React from 'react';
import i18n from 'i18next';
import './src/utils/i18n.ts';

// Main wrapper component, which is called only once on page load. We need to set correct language provided by url
// TODO investigate why `changeLanguage` is not working when used on hook in SSR
const wrapPageElement = ({ element, props }) => {
  i18n.changeLanguage(props.pageContext.langKey);
  return (
    <>{element}</>
  )
}

export default wrapPageElement
