export default {
  common_btn_download_app: 'Jetzt downloaden',
  common_btn_prev: 'Früher',
  common_btn_next: 'Nächste',
  common_btn_read_more: 'Mehr lesen',

  about_us_section_title: 'Wer ist Splint Invest?',
  about_us_section_text_1:
    '“Ist es nicht unfair, dass alternative Anlagen bisher vorwiegend wohlhabenden Personen offen stehen?” Diese Frage stellte sich unser Gründerteam und setzte sich gleich daran, eine Lösung zu finden. Die Idee Splint war geboren.',
  about_us_section_text_2:
    'In unserem Team findest du erfahrene Entrepreneurs, Software-Experten, Investment-Cracks und vor allem ganz viel Drive, ein nachhaltiges System zu schaffen, in dem jede Person von allen Anlageklassen profitieren kann.',

  slogan_section_heading: 'Deine neue Plattform für alternative Anlagen.',
  slogan_section_quote:
    '“Ich habe keine Zeit, mich ständig über Aktienkurse zu informieren. Whiskey hingegen ist greifbar.”',
  slogan_section_author: 'Fabian H., Zürich, Schweiz. ',

  how_to_step_1_headline: 'App herunterladen',
  how_to_step_1_text:
    'Du benutzt ein iPhone oder Android-Smartphone? Super, dann hast du praktisch alles, was du brauchst, um mit Splint loszulegen!',
  how_to_step_2_headline: 'Entdecken',
  how_to_step_2_text:
    'Whiskey, Wein, Uhren... Die Welt der alternativen Anlagen ist vielseitig und bietet für jeden Geschmack das richtige Asset - auch für dich.',
  how_to_step_3_headline: 'Asset auswählen',
  how_to_step_3_text:
    'Du erfährst direkt in der App alles Wichtige über die Anlage. Du hast dich für eine entschieden? Super! Dann kannst du ganz einfach Anteile (Splints) im Wert von je €50 kaufen.',
  how_to_step_4_headline: 'Portfolio diversifizieren',
  how_to_step_4_text:
    'Gratuliere! Du hast die ersten Schritte in der Welt der alternativen Anlagen gemacht. Dein Portfolio hältst du ganz easy im Überblick und kannst so der Wertentwicklung zusehen.',

  blog_section_title: 'Die aktuellsten Beiträge',

  what_is_splint_section_title: 'Was ist ein Splint?',
  what_is_splint_section_col_1_title: 'Bedeutung.',
  what_is_splint_section_col_1_text:
    'Zuerst scannen unsere Experten die Märkte nach neuen Anlagen. Haben sie eine gute Gelegenheit entdeckt, kaufen wir sie und teilen das Asset virtuell in Anteile von je €50 auf. Diese Anteile nennen wir Splints.',

  what_is_splint_section_col_2_title: 'Nutzen.',
  what_is_splint_section_col_2_text:
    'Mit jedem Wertanstieg deiner Anlage erhöht sich auch der Wert deiner Splints. Wird die Anlage verkauft, erhältst du deinen Anteil am Gesamtpreis.',

  testimonials_section_title: 'Das sagen unsere User',
  testimonial_section_user_1_title: 'Siro V., Winterthur, Schweiz.',
  testimonial_section_user_1_text:
    'Die App ist einfach zu nutzen, bietet aber auch viele Informationen über die einzelnen Anlagen, wenn man sich mehr einlesen will.',

  testimonial_section_user_2_title: 'Pascal T., Biel, Schweiz',
  testimonial_section_user_2_text: 'Gute App mit sehr interessanten Anlagemöglichkeiten – kann ich nur empfehlen.',
  testimonial_section_user_3_title: 'Sandra L., Basel, Schweiz',
  testimonial_section_user_3_text:
    'Wollte schon immer mal in Wein investieren, habe aber selber nicht viel Ahnung. App funktioniert top',

  opportunity_section_title: 'Deine Vorteile auf einen Blick',
  opportunity_section_item_1: 'Diversifikation',
  opportunity_section_item_2: 'Resistenz gegen Rezession',
  opportunity_section_item_3: 'Schutz vor Inflation',
  opportunity_section_item_4: 'Hohe Performance',

  faq_section_title: 'Schon losgelegt, oder hast du noch Fragen?',
  faq_section_text:
    'Du brauchst noch ein paar weiterführende Informationen, bevor du unsere App ausprobieren möchtest? Kein Ding: In unseren FAQ findest du weitere Details zu App, Anlagen und Splints.',
  faq_section_button: 'Zu den FAQ',

  footer_follow_text: 'Folge Splint',
  footer_contact_text: 'Kontakt',
  footer_text:
    'Splint Invest ist deine App für alternative Anlagen. Investiere in Whiskey, Wein, Uhren und mehr. Erhältlich für iOS und Android.',

  header_menu_title: 'Übersicht',

  header_menu_item_1_title: 'So geht es',
  header_menu_item_1_text:
    'Du weisst nicht, wie loslegen? Nur wenige Klicks trennen dich vor deinen ersten Schritten in die Welt der alternativen Anlagen.',

  header_menu_item_2_title: 'Über uns',
  header_menu_item_2_text:
    'Wer steckt eigentlich hinter Splint? Was war unsere Motivation?  Klicke hier und finds raus!',

  header_menu_item_3_title: 'Neuigkeiten',
  header_menu_item_3_text:
    'Was kann die Splint App Neues? Warum investieren wir in die Anlagen, die wir wählen? Hier findest du alle News',

  header_menu_item_4_title: 'Deine FAQs',
  header_menu_item_4_text:
    'Immer noch Fragen? Kein Problem! Check unsere FAQs, dort findest du bestimmt, was du suchst. Und wenn nicht: Einfach kontaktieren!',

  footer_all_rights_reserved: '© 2021 Splint. Alle Rechte vorbehalten.',

  footer_lang_german: 'Deutsch',
  footer_lang_english: 'Englisch',
  footer_terms: 'Datenschutz',

  home_page_title: 'Home',
  not_found_page_title: 'Nicht gefunden',

  not_found_text: 'Nicht gefunden',
  meta_description: 'Deine neue Plattform für alternative Anlagen.',
} as const;
